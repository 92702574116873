import 'core-js/es/object'; // ie11 support
import 'core-js/es/array'; // ie11 support
import 'core-js/es/promise'; // ie11 support
import 'core-js/es/symbol'; // ie11 support
import 'core-js/es/set'; //ie11 support
import 'core-js/es/map'; //ie11 support
import 'core-js/web/url'; //ie11 support
import 'core-js/web/url-search-params'; //ie11 support
import React from 'react';
import * as serviceWorker from './serviceWorker';
import { MuiThemeProvider } from '@material-ui/core';
import { createGenerateClassName, StylesProvider } from '@material-ui/styles';
import './compat/WindowUtils';
import ErrorBoundary from './core/ErrorBoundary';
import theme from './theme';

const nextJSSSeed = (() => {
  let counter = 0;
  return () => {
    return `s${++counter}`;
  };
})();

const Root = ({ children }) => {
  // Every root needs its own MUI JSS class name generator using
  // a different seed, otherwise we get collisions 💥
  // Sharing the same generator across roots does not appear to
  // resolve the issue.
  const muiGenerateClassName = createGenerateClassName({
    seed: nextJSSSeed(),
  });

  return (
    <ErrorBoundary>
      <MuiThemeProvider theme={theme}>
        <StylesProvider
          generateClassName={muiGenerateClassName}
          injectFirst={true}
        >
          {children}
        </StylesProvider>
      </MuiThemeProvider>
    </ErrorBoundary>
  );
};

const withRoot = (Element) => {
  const ElementWithRoot = (props) => {
    return (
      <Root>
        <Element {...props} />
      </Root>
    );
  };

  return ElementWithRoot;
};

const exportedElements = window.getExportedReactElementRegistry();
exportedElements.add({
  JobPage: () => {
    return import('./pages/Job');
  },
  CandidatesTable: () => {
    return import('./grids/CandidatesTable');
  },
  InsightsDetails: () => {
    return import('./grids/insightsDetails/App');
  },
  PipelineLeads: () => {
    return import('./grids/pipelineLeads/App');
  },
  RatingChart: () => {
    return import('./grids/pipelineLeads/components/RatingChart');
  },
  RecommendedJobs: () => {
    return import('./grids/recommendedJobs/App');
  },
  SavedReports: () => {
    return import('./grids/savedReports/App');
  },
  ManageReferrals: () => {
    return import('./pages/ManageReferrals');
  },
  SMSP2PUsers: () => {
    return import('./grids/SMSP2PUsers/App');
  },
});

exportedElements.applyRoot(withRoot);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

document.addEventListener('DOMContentLoaded', () => {
  window.createAndRenderExportedReactElementsFromAllScriptTags();
});

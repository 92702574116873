import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

const ExportedReactElement = ({ importer, props }) => {
  const Element = React.lazy(importer);

  return (
    <Suspense fallback="">
      <Element {...props} />
    </Suspense>
  );
};

ExportedReactElement.propTypes = {
  importer: PropTypes.func.isRequired,
  props: PropTypes.object.isRequired
};

export default ExportedReactElement;

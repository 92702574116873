import React from 'react';
import ExportedReactElement from './ExportedReactElement';

const createError = reason => {
  return new Error('Unable to create exported element: ' + reason);
};

class Registry {
  elements = {};
  ElementWithRoot = ExportedReactElement;

  add(name, config) {
    if (arguments.length === 1) {
      const importers = arguments[0];
      Object.entries(importers).forEach(kvp => {
        this.add(...kvp);
      });
    }

    // test if config is an object or an importer function.
    if (typeof config === 'function') {
      config = { importer: config };
    }

    this.elements[name] = config;
  }

  createExportedElement(name, props) {
    if (!this.elements.hasOwnProperty(name)) {
      throw createError(
        'element ' + name + ' has not been exported from the react application.'
      );
    }

    const config = this.elements[name];

    // Filter props key out of the config to make safe for use as spread props
    const safeConfigProps = (() => {
      const { props, ...safeConfigProps } = config;
      return safeConfigProps;
    })();

    return <this.ElementWithRoot {...safeConfigProps} props={props} />;
  }

  applyRoot(withRoot) {
    this.ElementWithRoot = withRoot(ExportedReactElement);
  }
}

export function createExportedReactElementRegistry() {
  return new Registry();
}

const defaultExportedReactElementRegistry = createExportedReactElementRegistry();
export default defaultExportedReactElementRegistry;

import React from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static propTypes = {
    fallback: PropTypes.func,
    children: PropTypes.node.isRequired
  };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error(error, info);
  }

  render() {
    const { fallback, children } = this.props;

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return fallback ? fallback() : null;
    }

    return children;
  }
}

export default ErrorBoundary;

import ReactDOM from 'react-dom';
import defaultExportedReactElementRegistry from './ExportedReactElementRegistry';

const reactComponentJsonMimeType = 'application/react-component+json';
const reactComponentScriptSelector =
  'body script[type="' + reactComponentJsonMimeType + '"]';

let exportedElements = defaultExportedReactElementRegistry;
const getExportedReactElementRegistry = () => exportedElements;
const setExportedReactElementRegistry = registry => {
  exportedElements = registry;
};
const createElement = async (name, props) => {
  return exportedElements.createExportedElement(name, props);
};
const renderElement = async (element, container) => {
  return new Promise(resolve => {
    const resolveWithElement = () => {
      resolve(element);
    };
    return ReactDOM.render(element, container, resolveWithElement);
  });
};

const createAndRenderExportedReactElement = async (container, name, props) => {
  const element = await createElement(name, props);
  await renderElement(element, container);
  return element;
};

const createAndRenderExportedReactElementFromScriptTag = async scriptOrId => {
  const script =
    scriptOrId instanceof HTMLScriptElement
      ? scriptOrId
      : document.getElementById(scriptOrId);

  const createFromScriptError = reason => {
    return new Error('Cannot create react element from script tag: ' + reason);
  };

  if (!script) {
    throw createFromScriptError(
      'the script could not be found by id: ' + scriptOrId
    );
  }

  if (!(script instanceof HTMLScriptElement)) {
    throw createFromScriptError(
      'the element is not an HTMLScriptElement: ' + scriptOrId
    );
  }

  if (!script.type || script.type !== reactComponentJsonMimeType) {
    throw createFromScriptError(
      'the script is not of type ' + reactComponentJsonMimeType
    );
  }

  const scriptParent = script.parentNode;
  if (!scriptParent) {
    throw createFromScriptError(
      'the script is not a child of another element.'
    );
  }

  const name = script.dataset.element;
  if (!name) {
    throw createFromScriptError(
      'the script is missing the data-element attribute.'
    );
  }

  const content = script.innerText.trim();
  const props = content ? JSON.parse(content) : {};

  // put the render container in the dom right before the script
  const container = document.createElement('div');
  scriptParent.insertBefore(container, script);

  // create and render the element
  const element = await createAndRenderExportedReactElement(
    container,
    name,
    props
  );
  script.remove();
  return element;
};

const createAndRenderExportedReactElementsFromAllScriptTags = async () => {
  const scripts = document.querySelectorAll(reactComponentScriptSelector);
  const results = [...scripts].map(
    createAndRenderExportedReactElementFromScriptTag
  );
  return await Promise.all(results);
};

Object.assign(window, {
  setExportedReactElementRegistry,
  getExportedReactElementRegistry,
  defaultExportedReactElementRegistry,
  createAndRenderExportedReactElement,
  createAndRenderExportedReactElementFromScriptTag,
  createAndRenderExportedReactElementsFromAllScriptTags
});

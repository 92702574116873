export const orange = '#F05123';
export const blue = '#1168B2';
export const lightBlue = '#EAF2F8';
export const green = '#77BC4B';
export const aqua = '#86D0E6';
export const darkGray = '#595959';
export const gray = '#D8D8D8';
export const lightGray = '#F2F2F2';
export const darkBlue = '#143055';

export const tableRowColors = {
  selected: lightBlue,
  hover: lightGray,
  hoverSelected: '#D5DEE5'
};

import { createMuiTheme } from '@material-ui/core';
import {
  orange,
  blue,
  lightBlue,
  darkGray,
  darkBlue,
  tableRowColors
} from './colors';
import createPalette from '@material-ui/core/styles/createPalette';

const palette = createPalette({
  primary: {
    main: orange
  },
  secondary: {
    main: blue,
    light: lightBlue
  },
  customColors: {
    orange,
    blue,
    lightBlue,
    darkGray,
    darkBlue
  }
});

const spacing = 8;

export default createMuiTheme({
  zIndex: {
    menu: 10000,
  },
  spacing: spacing,
  typography: {
    fontFamily: 'Open Sans',
    fontSize: 12,
    useNextVariants: true,
    button: {
      textTransform: 'none'
    }
  },
  palette: palette,
  overrides: {
    MuiButton: {
      contained: {
        backgroundColor: palette.common.white
      }
    },
    MuiMenu: {
      paper: {
        padding: 0
      }
    },
    MuiLink: {
      root: {
        color: palette.customColors.blue
      }
    },
    MuiPopover: {
      paper: {
        padding: `${spacing * 2}px`
      }
    },
    MuiTableCell: {
      root: {
        paddingRight: '16px'
      },
      head: {
        fontSize: 12,
        backgroundColor: blue,
        color: palette.common.white
      }
    },
    MuiTableRow: {
      root: {
        '&$hover:hover': {
          backgroundColor: tableRowColors.hover
        },
        '&$selected': {
          backgroundColor: tableRowColors.selected
        },
        '&$selected&$hover:hover': {
          backgroundColor: tableRowColors.hoverSelected
        }
      }
    },
    MuiTableSortLabel: {
      root: {
        color: palette.common.white,
        '&:focus': {
          color: palette.common.white,
          '& $icon': {
            opacity: '0.99'
          }
        },
        '&:hover': {
          color: palette.common.white,
          '& $icon': {
            color: palette.common.white
          }
        },
        '&$active': {
          color: palette.common.white,
          '&& $icon': {
            color: palette.common.white
          }
        }
      }
    },
    TableCheckbox: {
      root: {},
      head: {
        color: palette.common.white,
        '&$colorSecondary': {
          '&$checked': {
            color: palette.common.white,
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.08)'
            }
          },
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)'
          }
        }
      }
    },
    TableRow: {}
  }
});
